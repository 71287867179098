import React from "react";
import LockIcon from "@material-ui/icons/Lock";

const LockedPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        background: "white",
        display: "flex",
        marginTop:"100px",
        alignItems: "center",
        flexDirection: "column",
        textAlign:"center"
      }}
    >
      <LockIcon style={{ width: "100px", height: "100px", color: "red" }} />
      <h1>Shivane Designing Locked</h1>
      <span>Please contact the Netcom Computers to unlock the application.</span>
    </div>
  );
};

export default LockedPage;
